.app {
  margin: 0 250px;
  padding: 0 50px;

  .under-construction {
    text-align: center;
    margin-top: 50px;

    img {
      width: 800px;
    }
  }
}

.primary-button,
.secondary-button {
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px 25px;
  margin: 5px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    background-color: deeppink;
    cursor: pointer;
  }
}

.primary-button {
  color: cyan;
  background-color: deeppink;

  &:hover {
    background-color: hotpink;
  }
}

.secondary-button {
  color: purple;
  background-color: cyan;

  &:hover {
    background-color: deepskyblue;
  }
}

.center {
  text-align: center;
}
