.footer {
  background-color: white;
  padding: 25px;
  margin-top: 50px;

  .footer-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    margin: 0 100px;

    .column {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      font-size: 20px;
      font-weight: bold;

      a {
        margin: 5px;
        text-decoration: none;
        color: black;

        &:hover {
          cursor: pointer;
          color: hotpink;
        }
      }
    }
  }
}